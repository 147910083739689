import { Link } from "gatsby";
import styled from "@emotion/styled";
import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import { Title, Text } from "@components/Typography";
import BlogPostCategories from "@components/BlogPostCategories";

const PostDate = ({ postDate }) => {
  const formattedDate = postDate
    ? new Date(Date.parse(postDate)).toLocaleDateString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : null;

  return (
    <Div
      css={css`
        @media (min-width: 768px) {
          min-width: 130px;
          text-align: right;
        }
      `}
    >
      <Text textStyle="label">{formattedDate}</Text>
    </Div>
  );
};

const H = styled.header`
  width: 100%;
  margin-bottom: 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 767px) {
    h2 {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const PostHeader = ({ postDate, postTitle, slug }) => {
  return (
    <React.Fragment>
      {slug !== undefined && (
        <Link
          to={`/blog/${slug}`}
          css={css`
            transition: 0.15s all;
            &:hover {
              opacity: 0.75;
              cursor: pointer;
            }
          `}
        >
          <H>
            <Title
              tag="h2"
              size="lg"
              css={css`
                max-width: 640px;
                transition: 0.15s all;
                text-decoration: none;
              `}
            >
              {postTitle}
            </Title>
            <PostDate postDate={postDate} />
          </H>
        </Link>
      )}
      {!slug && (
        <H>
          <Title
            tag="h2"
            size="lg"
            css={css`
              max-width: 640px;
              transition: 0.15s all;
              text-decoration: none;
            `}
          >
            {postTitle}
          </Title>
          <PostDate postDate={postDate} />
        </H>
      )}
    </React.Fragment>
  );
};

export default PostHeader;
