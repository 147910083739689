import { css } from "@emotion/react";
import { BLACK } from "@styles/colors";
import { Div } from "./Markup";
import React from "react";
import IosDocumentOutline from "react-ionicons/lib/IosDocumentOutline";
import { Text } from "@components/Typography";

const DocumentDownload = ({ documentName, document }) => {
  return (
    <a
      href={document && document.url}
      download
      target="_blank"
      rel="noreferrer"
      css={css`
        display: block;
        margin-bottom: 16px;
      `}
    >
      <Div
        flex
        pt="xs"
        pb="xs"
        ai="center"
        css={css`
          line-height: 1.35em;
          font-family: "noah-regular";
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 4%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 68px;
          padding: 0px 24px;
          color: ${BLACK};
          border: 2px solid ${BLACK};
          transition: 0.15s all;
          background-color: transparent;
          cursor: pointer;

          svg {
            min-width: 40px;
            margin-right: 8px;
            margin-left: -8px;
            path {
              fill: ${BLACK};
            }
          }

          &:hover {
            background-color: ${BLACK};
            color: white;
            opacity: 1;

            svg {
              path {
                fill: white;
              }
            }
          }
        `}
      >
        <IosDocumentOutline fontSize="40px" />
        <Text textStyle="label">{documentName}</Text>
      </Div>
    </a>
  );
};

export default DocumentDownload;
